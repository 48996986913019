:root {
    --mld-scheme-my-labs: #558890;
    --mld-scheme-health-summary: #d74596;
    --mld-scheme-dashboard: #3284ff;
    --mld-scheme-lab-results: #64cf60;
    --mld-scheme-shop-tests: #ca32ff;
    --mld-scheme-support: #70dbd4;
    --mld-scheme-dashboard-2: #4c68cc;
    --mld-scheme-activate-kit: #16b9cf;
    --mld-scheme-return-kit: #ff9900;
    --mld-scheme-test-kits: #9644ff;
    --mld-shop-tests-covid-flu: #a2a9ad;
    --mld-shop-tests-blood-type: #692146;
    --mld-shop-tests-heart-health: #ab1d37;
    --mld-shop-tests-immune-health: #f78e28;
    --mld-shop-tests-thyroid-health: #00a84f;
    --mld-shop-tests-nutrient-health: #ffc700;
    --mld-shop-tests-general-health: #8bc7e8;
    --mld-shop-tests-diabetic-health: #28998b;
    --mld-shop-tests-men-s-complete-wellness: #003c4c;
    --mld-shop-tests-testosterone: #007482;
    --mld-shop-tests-basic-nutrition-and-lifestyle-risk: #00acbb;
    --mld-shop-tests-men-s-hormone-health: #0063a6;
    --mld-shop-tests-women-s-hormone-health: #df1783;
    --mld-shop-tests-women-s-complete-wellness: #f27fb2;
    --mld-kits-covid-rt-pcr: #66cadb;
    --mld-kits-covid-antibody: #ee2b37;
    --mld-kits-covid-igg: #8f489a;
    --mld-kits-covid-rapid-rt-pcr: #1d252c;
    --mld-kits-covid-rapid-antigen: #5b6670;
    --data-assessment-low: #fbcc62;
    --data-assessment-normal: #6ce559;
    --data-assessment-high: #f18385;
    --neutral-900: #101010;
    --neutral-800: #222222;
    --neutral-600: #555555;
    --neutral-500: #7e7e7e;
    --neutral-700: #3c3c3c;
    --neutral-300: #b0b0b0;
    --neutral-400: #969696;
    --neutral-200: #cccccc;
    --neutral-100: #ededed;
    --neutral-50: #f5f5f5;
    --primary-700: #003b4d;
    --neutral-0: #ffffff;
    --primary-900: #002733;
    --primary-500: #004e66;
    --primary-300: #006280;
    --primary-50: #5cc5e5;
    --secondary-500: #00ba8d;
    --success-700: #279600;
    --success-900: #1f7700;
    --success-500: #2eb200;
    --success-300: #36ce00;
    --success-50: #8ee86e;
    --warning-900: #a75000;
    --warning-700: #cf6300;
    --warning-300: #ff7a00;
    --warning-50: #ffb36d;
    --warning-500: #ec7100;
    --error-700: #a51400;
    --error-900: #790f00;
    --error-500: #d01900;
    --error-300: #f31d00;
    --text-0: #ffffff;
    --error-50: #ff6853;
    --text-100: #dcdcdc;
    --text-300: #959595;
    --text-400: #6d6d6d;
    --text-600: #222222;
    --link-300: #53acff;
    --link-600: #0085ff;
    --left-border: #579055;
    --card-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.15);
}
