@font-face {
    font-family: Nunito;
    src: local(Nuntio-Regular), url(../assets/fonts/Nunito-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
}
@font-face {
    font-family: Nuntio;
    src: local(Nuntio-Bold), url(../assets/fonts/Nunito-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
}
.DisplayLargeRegular {
    font-family: Nunito;
    font-size: 4.3rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: 1.5px;
}
.DisplayNormalRegular {
    font-family: Nunito;
    font-size: 3.583rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: 1.5px;
}
.Heading01Bold {
    font-family: Nunito;
    font-size: 2.986rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.5px;
}
.Heading01Regular {
    font-family: Nunito;
    font-size: 2.986rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: 1.5px;
}
.Heading02Bold {
    font-family: Nunito;
    font-size: 2.488rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.5px;
}
.Heading02Regular {
    font-family: Nunito;
    font-size: 2.488rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.5px;
}
.Heading03Bold {
    font-family: Nunito;
    font-size: 2.074rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}
.Subheading01Regular {
    font-family: Nunito;
    font-size: 2.074rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.5px;
}
.Heading03Regular {
    font-family: Nunito;
    font-size: 2.074rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 1.5px;
}
.Heading04Bold {
    font-family: Nunito;
    font-size: 1.728rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}
.Heading04Regular {
    font-family: Nunito;
    font-size: 1.728rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}
.Heading05Bold {
    font-family: Nunito;
    font-size: 1.44rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
}
.Subheading02Regular {
    font-family: Nunito;
    font-size: 1.44rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 1.5px;
}
.Heading05Regular {
    font-family: Nunito;
    font-size: 1.44rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: 1.5px;
}
.ParagraphLargeBold {
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
.Heading06Bold {
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8rem;
    letter-spacing: 1px;
}
.ParagraphLargeRegular {
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
.Heading06Regular {
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 1.5px;
}
.ParagraphNormalBold {
    font-family: Nunito;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
}
.ParagraphNormalRegular {
    font-family: Nunito;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
.CaptionRegular {
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
}
.ParagraphSmallBold {
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0.5px;
}
.ParagraphSmallRegular {
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
.FooterRegular {
    font-family: Nunito;
    font-size: 0.833rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1.5px;
}
.ParagraphNormalExtraBold {
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
.Heading05ExtraBold {
    font-family: Nunito;
    font-size: 1.2rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}
.Heading04ExtraBold {
    font-family: Nunito;
    font-size: 1.728rem;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.5px;
}
