@import '../node_modules/react-modal-video/css/modal-video.min.css';
@import url('./styles/colors.css');
@import url('./styles/typography.css');
@import url('./styles/buttons.css');

body {
    margin: 0;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
select {
    font-size: 16px;
}

/*
 * Register Kit CSS
*/
#registerkit-wrap {
    display: none;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
}

#splash-intro {
    background-color: #5fd0df;
    transition: all 0.5s ease;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#splash-intro h1 {
    margin-bottom: 1em;
}

#registerkit-header {
    display: none;
}

@media (max-width: 800px) {
    #registerkit-header img {
        max-height: 100px;
    }
}

#registerkit-footer {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background-color: #fff;
}

#registerkit-download-icons {
    display: none;
}

.download-icons img {
    height: 35px;
}

#registerkit-content {
    position: initial;
    text-align: center;
    margin: 0 auto;
}

.step {
    display: none;
    padding-bottom: 85px;
    height: 100%;
    padding: 15px;
}

.step.dark-background * {
    color: #fff;
}

.step h2 {
    color: #5fd0df;
    font-weight: 100;
    font-size: 2rem;
    margin-top: 0.5em;
}

.step h3 {
    font-size: 2.2rem;
    color: #123b4e;
}

.step.intro {
    background: linear-gradient(to left, #61cad9, #3a8799);
    display: table;
}

.step.intro h2 {
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 3rem;
    margin-bottom: 1em;
}

.step.intro h3 {
    font-weight: 100;
    font-size: 1.75rem;
}

.step.contents {
    background: linear-gradient(to left, #3a8799, #1a4254);
}

.step.contents .slide h4 {
    color: #fff;
}

.step.contents .slide img {
    height: 300px;
    width: 300px;
}

/* .step.contents div, */
.step.intro div {
    display: table-cell;
    vertical-align: middle;
    position: relative;
    top: -6em;
}

#splash-intro h1 {
    color: #fff;
    font-size: 4rem;
    letter-spacing: 2px;
}

#registerkit-navigation {
    display: flex;
    margin: auto;
}

.nav-button {
    font-weight: 500;
    width: 100%;
    padding: 1em 0;
    font-size: 1.15rem;
    border: 0 none;
    cursor: pointer;
}
#file-upload-visual-button {
    padding: 0.5em 1.5em;
    background-color: orange;
    color: #fff;
    font-weight: 800;
    cursor: pointer;
    margin: 1em;
    display: inline-block;
}

#guest-layout {
    width: 100%;
}

#root,
#guest-layout,
.is-fullheight {
    min-height: 100vh;
}

.required-record {
    font-size: 0.7em;
}
.required-record span.record-icon,
.required-record span.record-icon span {
    padding: 5px;
    border-radius: 15px;
    display: inline-flex;
}
.required-record span.record-icon {
    border: 1px solid #000;
}
.required-record span.record-icon span {
    background-color: #f00;
    height: 15px;
    width: 15px;
}

/*
 * Navigation
 */
.navbar-brand {
    margin: auto !important;
}
.navbar-brand .navbar-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: auto;
}
.navbar-brand .navbar-item img {
    max-height: 5rem;
}

/*
 * Animations
 */
@keyframes blink {
    0% {
        opacity: 0.7;
    }
    40% {
        opacity: 0.9;
    }
    50% {
        opacity: 1;
    }
    60% {
        opacity: 0.9;
    }
    100% {
        opacity: 0.7;
    }
}
.skeleton-blink {
    animation: blink 1s infinite;
}
.skeleton-blink article {
    min-height: 200px;
}
.skeleton-blink article.header,
.skeleton-blink article.footer {
    min-height: 70px;
}

/*
 * Footer
 */
footer.footer {
    padding: 0;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 100;
}
footer.footer > div.content {
    margin: 0;
}
footer #registerkit-navigation {
    display: flex;
}
footer #registerkit-navigation .nav-button {
    width: 50%;
    padding: 1em 0;
    font-size: 1rem;
    cursor: pointer;
    border: none;
}
footer.footer a {
    color: #17386d !important;
}
footer #registerkit-navigation .nav-button-full {
    width: 100%;
}

/*
 * Sections
 */
.splash-cover,
#guest-layout section {
    text-align: center;
    padding: 15px;
}
#guest-layout section {
    padding-bottom: 4em;
}
#guest-layout .splash-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;
}
.splash-cover h1.title {
    font-size: 4rem;
    letter-spacing: 2px;
}

section.hero.is-info {
    background-color: #5fd0df;
}

#guest-layout section.hero.is-fullheight .hero-body {
    align-self: stretch;
    padding: 0;
}

section.gradient.light-bg {
    background: linear-gradient(to left, #61cad9, #3a8799);
}
section.gradient.dark-bg {
    background: linear-gradient(to left, #3a8799, #1a4254);
}

section.gradient .title,
section.gradient .subtitle {
    color: #fff;
}

section.gradient .title {
    font-size: 2em;
    font-weight: 600;
    line-height: 3rem;
    margin-bottom: 1em;
}

/*
 * <INTRO SECTION>
 */

/* Based on the redesign of 5/21/21, changing the colors*/

.telehealth-text {
    color: white;
    text-align: center;
}

.coloredLink {
    color: white !important; /* Quick fix to guarentee it shows*/
}

.introParagraphSpacing > p {
    padding-top: 2.5%;
}

#guest-layout section.intro.pink-bg {
    background: linear-gradient(180deg, rgba(207, 74, 155, 1) 20%, rgba(67, 146, 207, 1) 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-bottom: 0;
    position: absolute;
    min-height: 100vh;
    width: 100%;
    top: 0;
    z-index: 50;
}
section.intro .title,
section.intro .subtitle {
    color: #fff;
}
section.intro .title {
    padding-top: 3%;

    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 1.6em;

    line-height: 1.2em;
    margin-bottom: 10px;
}
section.intro .subtitle {
    font-weight: 900;
    font-size: 1.4em;
    margin-top: 0 !important;
    margin-bottom: 30px;
}
section.intro p strong,
section.intro p {
    color: #fff;
}
section.intro p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 15px;
}
section.intro p.airplane {
    font-size: 20px;
    font-weight: 1000;
}
section.intro p a:hover {
    text-decoration: none;
}
@media (min-width: 768px) {
    section.intro .title {
        font-size: 2.2em;
    }
    section.intro .subtitle {
        font-size: 1.8em;
    }
    section.intro p {
        font-size: 22px;
    }
    section.intro p.airplane {
        font-size: 26px;
    }
}
@media (min-width: 960px) {
    section.intro .title {
        font-size: 5.5vw;
    }
    section.intro .subtitle {
        font-size: 4vw;
    }
    section.intro p {
        font-size: 28px;
    }
    section.intro p.airplane {
        font-size: 32px;
    }
}

section.gradient .subtitle {
    font-weight: 100;
    font-size: 1.85em;
}
section.gradient h4 {
    color: #fff;
    font-weight: 800;
    padding-top: 1rem;
}

section.white-bg h2 {
    color: #5fd0df;
    font-weight: 100;
    font-size: 2rem;
    margin-top: 0.5em;
}

section.white-bg h3 {
    font-size: 2.2rem;
    font-weight: 700;
    color: #123b4e;
}

section.white-bg p {
    color: #777;
    margin-bottom: 1em;
}

section.done {
    background-color: #123b4e;
}

section.done .title {
    color: #5fd0df;
    padding-top: 1em;
}

section.done .questions {
    padding: 1em 0;
}

section.done .questions h6 {
    color: #fff;
    font-size: 0.85em;
    font-weight: 600;
}

section.done .questions div,
section.done .questions p {
    color: #fff;
    font-size: 0.75em;
}

section.done .questions img {
    max-height: 90px;
}

#registration-form {
    max-width: 600px;
    text-align: left;
    margin: 2rem auto;
}

#registration-form .select,
#registration-form .select select {
    width: 100%;
}

#registration-form .control.has-icons-left .input,
#registration-form .control.has-icons-left .select select {
    padding: 1.5em 3em 1.5em 1.5em;
    padding-left: 3em;
}

#registration-form .radio {
    font-size: 1rem;
}

#registration-form .radio input {
    margin-right: 5px;
}

#registration-form label.label:not(:last-child) {
    margin-bottom: 0;
}

#guest-layout {
    background-color: var(--neutral-0);
}

section.white-bg .legal-jargon,
section.white-bg .legal-jargon label.checkbox,
section.white-bg .legal-jargon p {
    font-size: 0.95rem;
}

section.white-bg .legal-jargon label.checkbox input[type='checkbox'] {
    margin-right: 5px;
}

#record-button {
    background-color: #f00;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    margin: auto;
}
.error-label {
    color: #f14668 !important;
}

/* Used to show certain boxes are coming soon*/
.coming-soon-box {
    opacity: 0.5;
}

.coming-soon-text {
    position: relative;
    top: 0px;
    z-index: 10;
    color: white;
}

/* Used to cover the header so they can select their box*/
.covers-header {
    z-index: 50;
}

/* Used to make the heeader on select kit look nicer*/
.select-kit-header {
    margin-top: 20px;
}

/* Makes the images smaller */
.select-kit-image {
    max-height: 50vh;
    outline: 30px solid #000;
    margin-bottom: 100px;
}

.available-kit:hover {
    cursor: pointer;
    filter: brightness(80%);
}

/* Quick-and-dirty setup (hence the overrides), to get the antibody h2/h3 looking okay and mimicing the instructions.*/
.antibody-step-number {
    font-size: 4rem !important;
    color: #123b4e !important;
    margin-bottom: 0;
}

.antibody-step-description {
    color: #5fd0df !important;
    font-weight: 400 !important;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0.5em;
}

.antibody-step-1-italic-text {
    font-style: italic;
    color: #5fd0df !important;
    font-weight: 500 !important;
}

/* Final page once they've entered everything
.*/

.final-page-top-text {
    color: #5fd0df;
    padding-top: 1em;
    font-size: 10vh;
}

.final-page-middle-text {
    color: #5fd0df !important;
    margin-top: 20vh;
    font-size: 3vh;
}

.final-page-info-text {
    color: #fff;
}
