.PrimaryLarge {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: solid 1px var(--neutral-0);
    width: 232px;
    height: 35px;
    flex-grow: 0;
    font-family: Nunito;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: var(--text-0);
    text-decoration: none;
}

.RegisterKit {
    background-color: var(--mld-scheme-activate-kit);
    transition: 0.2s;
}

.RegisterKit:hover {
    background-color: var(--primary-300);
}

.RegisterKit:active {
    background-color: var(--primary-50);
}

.ViewMyLabResults {
    transition: 0.2s;
}

.ViewMyLabResults:hover {
    background-color: var(--primary-300);
}

.ViewMyLabResults:active {
    background-color: var(--primary-50);
}

.ViewMyHealthSummary {
    transition: 0.2s;
}

.ViewMyHealthSummary:hover {
    background-color: var(--primary-300);
}

.ViewMyHealthSummary:active {
    background-color: var(--primary-50);
}

.ViewKitInstructions {
    background-color: var(--secondary-500);
    transition: 0.2s;
}

.ViewKitInstructions:hover {
    background-color: var(--primary-300);
}

.ViewKitInstructions:active {
    background-color: var(--primary-50);
}

.NavLink {
    transition: 0.2s;
}

.NavLink:hover {
    font-weight: 800;
}
